.quick-search
  display: none
  position: absolute
  top: 60px
  left: 0
  width: 100%
  max-width: 500px
  background: #052837
  color: #ffffff
  z-index: 1500

input.quick-search__field
  width: 100%
  /*height: 0.25rem*/
  background: #052837
  color: #ffffff
  border-radius: 0

.quick-search__submit
  background: none
  border: none
  font-family: 'Font Awesome 5 Pro'
  font-style: normal
  font-variant: normal
  font-weight: 400
  text-rendering: auto
  -webkit-font-smoothing: antialiased
  padding: 20px

.quick-search.open
  display: flex
