.quick-lang
  display: none
  position: absolute
  top: 60px
  left: 0
  width: 100%
  max-width: 500px
  background: #052837
  color: #ffffff
  font-size: 2rem
  z-index: 2000

  a
    margin: 2rem
    display: block

  a:link 
    display: block
    color: #ffffff

  /* visited link */
  a:visited 
    display: block
    color: #ffffff
  
  /* mouse over link */
  a:hover 
    display: block
    color: #ffffff
  
  /* selected link */
  a:active 
    display: block
    color: #ffffff

input.quick-lang__field
  width: 100%
  /*height: 0.25rem*/
  background: #052837
  color: #ffffff
  border-radius: 0

.quick-lang__submit
  background: none
  border: none
  font-family: 'Font Awesome 5 Pro'
  font-style: normal
  font-variant: normal
  font-weight: 400
  text-rendering: auto
  -webkit-font-smoothing: antialiased
  padding: 20px

.quick-lang.open
  display: block
